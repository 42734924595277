.kepzes-aloldal {
  .kepzes-felsorolas {
    margin: 0;
    padding: 0;

    .table {
      .padding-50 {
        width: 50px;
      }

      thead {
        background: #b7e1ca;
        padding: 15px 50px;

        th {
          text-transform: uppercase;
          line-height: 1.2;
        }
      }

      tbody {

        tr {
          padding: 0 50px;

          .info-resz {
            padding-left: 32px;
            position: relative;

            .info-link {
              position: absolute;
              left: 0;
            }
          }

          td {
            vertical-align: middle;

            .ajanlat-link {
              font-weight: 900;
              color: $zold;
              text-transform: uppercase;
              letter-spacing: .75px;

              &:hover,
              &:focus {
                color: $sotetkek;
              }
            }
          }

        }

        >tr:nth-child(odd) {
          background-color: #fff9e9;
        }

        >tr:nth-child(even) {
          background-color: #fdfdfd;
        }
      }
    }
  }

  .ajanlat-link {
    font-weight: 900;
    color: $zold;
    text-transform: uppercase;
    text-transform: uppercase;
    letter-spacing: .75px;

    &:hover,
    &:focus {
      color: $sotetkek;
    }
  }

  .ajanlat {
    padding: 0 50px 50px;

    >h4 {
      color: $zold;
      font-weight: 300;
      font-size: 36px;
      text-transform: uppercase;
    }

    label {
      text-transform: uppercase;
    }

    input,
    select {
      border-radius: 0;
      border-color: #a3a3a3;

      &:focus {
        border-color: $zold;
        box-shadow: inset 0 1px 1px rgba(red($zold), green($zold), blue($zold), 0.075), 0 0 8px rgba(red($zold), green($zold), blue($zold), 0.6);
      }
    }

    select {
      background: white url('/images/blokk-nyil-le.png') no-repeat 98% 50%;
      -webkit-appearance: none;
      -moz-appearance: none;
      text-indent: 1px;
      text-overflow: '';

    }
  }

  .max-application-count-text {
    margin-bottom: 8px;
  }

  .kepzes-jelentkezes {
    padding: 30px 20px;

    /* .multiselect[required] {
      display: block !important;
      height: 0;
      margin-bottom: -2px;
      opacity: 0;
      padding: 0;
    } */

    .btn-success {
      color: $feher;

      &:hover,
      &:focus {
        color: $sotetkek;
        background: $feher;
        border-color: $sotetkek;
      }
    }

    h3 {
      font-size: 36px;
      color: $zold;
      text-align: center;
      text-transform: uppercase;
      font-weight: 300;
    }

    input,
    select,
    textarea {
      border-radius: 0;
      border-color: #a3a3a3;

      &:focus {
        border-color: $zold;
        box-shadow: inset 0 1px 1px rgba(red($zold), green($zold), blue($zold), 0.075),
          0 0 8px rgba(red($zold), green($zold), blue($zold), 0.6);
      }
    }

    @media (max-width: $screen-xs-max) {
      margin: 20px 15px;
    }

    @media (max-width: 320px) {
      h3 {
        font-size: 30px;
      }
    }

    .form-title {
      text-align: center;
      font-size: 18px;
      color: $zold;
      text-transform: uppercase;
      font-weight: bold;
      border-bottom: 1px solid $zold;
      margin-bottom: 35px;
      margin-top: 35px;   
      padding-bottom: 5px; 

      &.form-title-applicant {
        margin-top: 15px;
        font-size: 15px;
        width: 75%;
        margin: 10px auto;
      }
    }

    hr {
      border-color: $zold;
      margin: 25px 0;
    }

    .kurzus-desc {
      font-size: 16px;
      text-transform: uppercase;

      &.bold {
        font-weight: bold;
      }
    }
  }
}