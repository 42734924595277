.page-header {
  .container{
    margin: 0;
    width: 100%;
  }

  .zold-layer.vege{
    right: 0 !important;
  }
  h1 {
    font-size: 50px !important;
  }
}