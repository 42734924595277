.fooldal {
  .carousel-control {
    background-image: none;
    img {
      position: absolute;
      top: 50%;
    }
    &.left {
      img {
        left: 30px;
        width: 30px;
      }
    }
    &.right {
      img {
        right: 30px;
        width: 30px;
      }
    }
  }

  .carousel-caption {
    padding: 30px 60px 30px 100px;
    .slider-cim {
      font-size: 30px;
    }

    p {
      font-size: 15px;
      margin-bottom: 25px;
    }

    .btn-success {
      font-size: 16px;
    }
  }

  .fooldal-rolunk {
    padding: 60px 150px;
  }

  .harmas-resz {
    > .row {
      > div {
        .btn {
          padding: 8px 25px;
          font-size: 18px;
        }
      }
    }
  }

  .hirlevel{

    h3{
      font-size: 36px;
    }
  }
}