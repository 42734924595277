.szoveg {
  padding: 40px 50px;
  margin-bottom: 10px;

  .blokk-elem {
    .panel-felso {
      margin: 1px -50px;
    }
  }

  p {
    font-size: 15px;
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: bold;
  }

  h1 {
    margin-top: 10px;
  }

  h1, h3, h5 {
    color: $zold;
  }

  ol {
    list-style-position: outside;
    padding-left: 25px;
  }

  ul {
    list-style-position: outside;
    list-style: none;
    padding-left: 27px;

    li {
      color: #555;
      font-weight: 300;
      line-height: 1.5;
      padding-left: 15px;
      position: relative;
      margin-bottom: 10px;
    }

    li::before {
      content: "▪";
      color: $zold;
      display: inline-block;
      width: 1em;
      height: 0.6em;
      margin-left: -0.5em;
      font-size: 25px;
      position: absolute;
      left: 0;
      top: -0.3em;
    }
  }

  a {
    color: $zold;

    &:hover, &:focus {
      color: darken($zold, 10%);
    }
  }

  img {
    max-width: 100%;
    @include img-responsive();
  }

  figcaption {
    color: $zold;
    font-size: 16px;
    margin-bottom: 15px;
  }

  blockquote {
    border-color: $zold;
  }

  table {
    margin-bottom: 25px;
    width: 100%;
    border: 0;
    thead {
      > tr {
        background-color: #e3e3e3;
        border: 1px solid lighten($szurke, 20%);
        color: darken($text-color, 15%);
        > th {
          border: 1px solid lighten($szurke, 20%);
          vertical-align: middle;
          padding: 5px 10px;
          color: $zold;
          background-color: #e3e3e3;
          text-transform: uppercase;
        }
      }
    }

    tbody {
      > tr {
        border: 1px solid lighten($szurke, 40%);
        > td, > th {
          border: 1px solid lighten($szurke, 40%);
          padding: 13px 10px;
          vertical-align: middle;
          color: $text-color;
        }
      }
      > tr:nth-child(odd) {
        background-color: #fff;
      }

      > tr:nth-child(even) {
        background-color: #f2f2f2;
      }
    }
  }
}