.referencia-aloldal{
 .media-left{
   min-width: 240px;
   max-width: 240px;
   @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max){
     max-width: 160px;
     min-width: 160px;
   }
   img{
     max-width: 240px;
     display: block;
     margin-left: auto;
     margin-right: auto;
     @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max){
       max-width: 160px;
     }
   }
 }

  .media-body{
    @media (max-width: $screen-sm-max){
      text-align: center;
    }
  }
}