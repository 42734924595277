.kapcsolat {

  .kapcsolat-szoveg {
    padding: 50px;

    h2 {
      font-size: 21px;
      margin-bottom: 5px;
    }

    p{
      margin-bottom: 10px;
    }

    h3 {
      color: $zold;
      font-weight: bold;
      font-size: 18px;
    }

    a{
      font-weight: 600;

      &:hover,&:focus{
        color: $sotetkek;
      }
    }
  }
}