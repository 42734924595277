footer{
  .footer-felso{
    text-align: center;
     img{
      margin-right: 0;
      display: block;
      margin-left: auto;
      margin-right: auto !important;
    }
  }
}