footer {
  .footer-felso {
    img {
      margin-right: 5px;
    }
    > a {
      font-size: 14px;
    }
  }
  .text-right {
    text-align: center;
  }
  .social {
    a img {
      width: 50px;
    }
  }
}
