header {
  .navbar-default {

    .navbar-nav {
      > div.menu-logo {
        padding-left: 15px;
        padding-right: 15px;
        .navbar-brand > img {
          max-width: 150px;
          margin: 0;
        }
      }

      > div {
        .menu-link {
          font-size: 14px;
        }

        a {
          &.kereses-lenyilo {
            font-size: 14px;
            @media (max-width: 1100px) {
              font-size: 0px;
            }
            img {
              max-width: 30px;
            }
          }
        }
      }
    }
  }
}