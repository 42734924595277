header {
  .navbar-default {
    background-color: white;
    border: none;

    .container-fluid {
      padding: 0;
    }

    .menu-link {
      font-size: 17px;
    }

    .navbar-nav {
      width: 100%;
      display: flex;
      @media (min-width: $screen-sm-min) {
        justify-content: space-between;
        align-items: stretch;
      }
      &::before, &::after {
        display: none;
      }

      > div {
        font-size: 17px;
        color: #808080;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-grow: 1;
        border-left: 1px solid $szurke;
        cursor: pointer;

        @media (max-width: $screen-xs-max) {
          display: inline-block;
        }
        a {
          text-transform: uppercase;
          color: $text-color;
          cursor: pointer;
          &.kereses-lenyilo {
            color: #999;
          }

        }
        &:hover, &:focus {
          background: $sotetkek;
          > a {
            background: transparent;
            color: $feher;
          }
        }

        &.menu-logo {
          flex-grow: 0;
          border: none;
          padding-left: 35px;
          padding-right: 35px;

          img {
            margin-top: -10px;
          }

          &:hover, &:focus {
            background: transparent;
          }
        }
        &.active {
          a, a:hover {
            background: transparent;
            color: $zold;
          }
        }

        &.dropdown {
          > a {
            padding-right: 25px;
            background: transparent url('/images/menu-nyil-le.png') no-repeat 100% 50%;
          }
          &:hover, &:focus {
            > a {
              background: transparent url('/images/menu-nyil-fel.png') no-repeat 100% 50%;
            }
          }
        }

        &.open {
          background: rgba(24, 31, 49, 0.9);

          a {
            color: white;
          }
          a:hover, a:focus {
            background: transparent;
            color: $zold;
          }

          &.dropdown {
            > a {
              background: transparent url('/images/menu-nyil-fel.png') no-repeat 100% 50%;
            }
          }
        }

        .dropdown-menu {
          margin-top: 0;
          background: $sotetkek;
          border-color: $sotetkek;
          border-radius: 0;
          padding-left: 7px;
          padding-right: 10px;

          &:last-of-type{
            left: auto;
            right: 0;
          }

        }

      }
      > span {
        width: 1px;
        background: #d6d6d6;
      }
    }
  }

  .kereses-sav {
    background: $sotetkek;
    padding: 30px 10px;
    display: none;
    position: relative;

    .btn-success {
      color: $feher;
      &:hover,
      &:focus {
        color: $sotetkek;
        background: $feher;
        border-color: $sotetkek;
      }
    }

    .form-group{
      width: calc(100% - 105px);
      input{
        width: calc(100% - 305px);
      }
    }

    label {
      color: white;
      font-weight: 300;
    }

    input {
      background: transparent;
      color: white;
      border: none;
      border-radius: 0;
      border-bottom: 1px solid white;
      width: 63%;
      margin: 0 30px;

      &:focus {
        border-color: $feher;
        box-shadow: inset 0 1px 1px rgba(red($feher), green($feher), blue($feher), 0.075), 0 0 8px rgba(red($feher), green($feher), blue($feher), 0.6);
      }
    }

    .search-close {
      background: transparent;
      position: absolute;
      top: 50%;
      right: 50px;
      transform: translate(0, -50%);
      border: none;
      opacity: 0.7;

      &:hover, &:focus {
        opacity: 1;
      }
    }
  }
}