.tartalom-sav {
  padding-right: 15px;
}

.page-header {
  .zold-layer {
    &.balra {
      display: none;
    }

    &.vege {
      width: 100%;
    }
  }

  h1 {
    font-size: 27px;
    width: 90%;
    text-align: center;
    max-width: 90%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.hirlevel {
  padding: 30px 20px;

  h3 {
    font-size: 30px;
    text-align: center;
  }
}

@media (max-width: $screen-xs-max) {

  .hirlevel {
    padding: 30px 20px;

    h3 {
      font-size: 36px;
      text-align: center;
    }
  }

  .oldalsav{
    padding-top: 30px;
    padding-left: 15px;
  }
}


@media (max-width: 320px) {

  .hirlevel {

    h3 {
      font-size: 30px;
    }
  }
}