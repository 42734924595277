.ajanlatkeres-aloldal {
  .ajalnlatkeres {
    label {
      text-transform: uppercase;
    }

    input, select, textarea {
      border-radius: 0;
      border-color: #a3a3a3;

      &:focus {
        border-color: $zold;
        box-shadow: inset 0 1px 1px rgba(red($zold), green($zold), blue($zold), 0.075), 0 0 8px rgba(red($zold), green($zold), blue($zold), 0.6);
      }
    }

    select {
      background: white url('/images/blokk-nyil-le.png') no-repeat 98% 50%;
      -webkit-appearance: none;
      -moz-appearance: none;
      text-indent: 1px;
      text-overflow: '';
    }

    .valaszto {
      .checkbox {
        margin: 0;
        padding: 10px 0;
      }
      input[type=checkbox] {
        display: none;
      }

      input[type=checkbox] + label {
        font-weight: 300;
        color: #8d8d8d;
        margin: 0;
        padding: 13px 0;
        width: 100%;
        &.nagy {
          background: url('/images/checkbox-nagy.png') no-repeat 5px 50%;
          padding-left: 45px;
        }

        &.kicsi {
          background: url('/images/checkbox-kicsi.png') no-repeat 15px 50%;
          padding-left: 45px;
          padding-right: 10px;
          text-transform: none;
        }
      }

      input[type=checkbox]:checked + label {
        background: #fff4d4;
        font-weight: bold;

        &.nagy {
          background: #fff4d4 url('/images/checkbox-nagy-select.png') no-repeat 5px 50%;
        }
        &.kicsi {
          background: #fff4d4 url('/images/checkbox-kicsi-select.png') no-repeat 15px 50%;
        }
      }

      .collapse, .collapsing {
        margin-top: -10px;
        background: #fff4d4;
        font-size: 12px;
      }
    }

    .btn-success{
      font-size: 20px;
      padding: 8px 30px 12px;
    }
  }
}