.letoltheto-dokumentumok {
  .panel-group{
    margin-bottom: 0;
  }

  .panel-felso {
    margin: 0 0px 1px -15px !important;
    border-radius: 0;
    border: 0;

    .panel-heading {
      padding: 20px 50px 0 100px;
      background: transparent url('/images/blokk-nyil-fel.png') no-repeat 50px 50%;
      min-height: 60px;
      border-radius: 0;
      border: 0;
      text-transform: uppercase;
      h4 {
        font-size: 21px;
        font-weight: bold;
        
      }
    }
    .panel-heading.collapsed {
      color: $feher;
      background: $sotetkek url('/images/blokk-nyil-le.png') no-repeat 50px 50%;
    }

    .panel-body {
      padding: 5px 50px;
      border-top: none !important;

      .media {
        border-top: 1px solid #ddd;
        padding-top: 30px;
        margin-bottom: 30px;

        .media-body {
          padding-left: 40px;
          font-size: 15px;

          .media-heading {
            font-weight: bold;
            margin-bottom: 15px;
          }

          .letoltes-link {
            display: block;
            font-weight: 600;
          }
        }
      }
    }
  }
}