.fooldal {
  .carousel-inner{
    display: flex;
    justify-content: center;
  }

  .carousel-control {
    background-image: none;
    img {
      position: absolute;
      top: 50%;
    }
    &.left {
      img {
        left: 30px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    &.right {
      img {
        right: 30px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  .carousel-caption {
    text-align: right;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 635px;
    padding: 30px 60px 30px 100px;
    background: rgba(red($zold), green($zold), blue($zold), 0.9);

    .slider-cim {
      font-weight: 900;
      color: $feher;
      font-size: 35px;
      text-shadow: none;
      margin-top: 0;
      margin-bottom: 10px;
    }

    p {
      color: $sotetkek;
      font-weight: 400;
      font-size: 16px;
      text-shadow: none;
      margin-bottom: 30px;
    }

    .btn-success {
      color: $feher;
      font-weight: 900;
      font-size: 18px;
      padding: 7px 25px 7px;
      &:hover,
      &:focus {
        color: $sotetkek;
        background: $feher;
        border-color: $feher;
      }
    }
  }

  .carousel-indicators {
    left: auto;
    margin: auto;
    right: 50px;
    width: auto;
    li {
      border-radius: 0;
      width: 59px * 0.8;
      height: 12px *0.8;
      background: $sotetkek;
      border-color: $sotetkek;

      &.active {
        background: $zold;
        border-color: $zold;
        margin: 1px;
      }
    }
  }

  .harmas-resz {
    margin-top: 80px;
    background: #3c3c3c;
    > .row {
      > div {
        min-height: 230px;
        padding: 50px 30px 35px;

        img {
          margin-bottom: 32px;
          height: 120px;
        }

        .btn {
          padding: 10px 30px;
          color: $zold;
          background: $sotetkek;
          border-color: $zold;
          text-transform: uppercase;
          font-size: 20px;

          &:hover,
          &:focus {
            color: $feher;
            background: $zold;
          }
        }

        &:first-child {
          border-right: 1px solid $szurke;
        }

        &:last-child {
          border-left: 1px solid $szurke;
        }
      }
    }
  }

  .ajanlatkero-szoveg {
    margin-top: 45px;
    margin-bottom: 50px;

    h1,
    h2,
    h3 {
      color: $zold;
      font-weight: bold;
      font-size: 36px;
      text-transform: uppercase;
      margin-bottom: 50px;
    }

    p {
      font-weight: 500;
      font-size: 18px;
    }
  }

  .fooldal-ajanlatkero {
    padding: 35px 50px 40px;

    .btn-success {
      color: $feher;
      &:hover,
      &:focus {
        color: $sotetkek;
        background: $feher;
        border-color: $sotetkek;
      }
    }

    h3 {
      font-size: 36px;
      color: $zold;
      text-align: center;
      text-transform: uppercase;
      font-weight: 300;
    }
    input,
    select,
    textarea {
      border-radius: 0;
      border-color: #a3a3a3;

      &:focus {
        border-color: $zold;
        box-shadow: inset 0 1px 1px
            rgba(red($zold), green($zold), blue($zold), 0.075),
          0 0 8px rgba(red($zold), green($zold), blue($zold), 0.6);
      }
    }
  }

  #mc_embed_signup {
    .btn-success {
      color: $feher;
      &:hover,
      &:focus {
        color: $sotetkek;
        background: $feher;
        border-color: $sotetkek;
      }
    }
  }

  #mc-embedded-subscribe {
    margin: 1em 0 1em;
  }

  .fooldal-rolunk {
    padding: 80px 250px;
    font-size: 18px;
    font-weight: 300;
    //text-transform: uppercase;

    h1,
    h2,
    h3 {
      font-size: 36px;
      color: $zold;
    }
  }

  .blog {
    .btn-success {
      color: $feher;
      &:hover,
      &:focus {
        color: $sotetkek;
        background: $feher;
        border-color: $sotetkek;
      }
    }
  }
}
