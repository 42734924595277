.page-header {
  margin-top: 0;
  margin-bottom: 80px;
  padding: 0;
  border: 0;
  background: #bc931c url('/images/page-header.jpg') no-repeat;
  height: 200px;
  position: relative;

  .zold-layer {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    background: rgba(red($zold), green($zold), blue($zold), 0.9);
    z-index: 10;

    &.balra {
      right: 100%;
      left: -50%;
    }

    &.vege {
      width: 66.6666666667%;
    }
  }

  .container {
    z-index: 100;
    position: relative;
    height: 100%;
  }

  h1 {
    margin: 0;
    color: $feher;
    position: absolute;
    z-index: 100;
    top: 50%;
    left: 15px;
    transform: translate(0, -50%);
    font-size: 50px;
    text-transform: uppercase;
    font-weight: 900;
    width: 66.6666666667%;
  }
}

.bg-white {
  background-color: white;
}

.blokk-elem {
  .panel-felso {
    margin: 1px -15px;
    border-radius: 0;
    border: 0;

    .panel-heading {
      padding: 22px 50px 10px 100px;
      background: transparent url('/images/blokk-nyil-fel.png') no-repeat 50px
        50%;
      min-height: 60px;
      border-radius: 0;
      border: 0;
      text-transform: uppercase;
    }
    .panel-heading.collapsed {
      color: $feher;
      background: $sotetkek url('/images/blokk-nyil-le.png') no-repeat 50px 50%;
    }

    .panel-body {
      padding: 5px 50px;
    }
  }
}

.hirlevel {
  padding: 35px 50px 40px;

  h3 {
    color: $zold;
    font-weight: 300;
    font-size: 36px;
    text-transform: uppercase;
  }

  input {
    border-radius: 0;
    border-color: #a3a3a3;

    &:focus {
      border-color: $zold;
      box-shadow: inset 0 1px 1px
          rgba(red($zold), green($zold), blue($zold), 0.075),
        0 0 8px rgba(red($zold), green($zold), blue($zold), 0.6);
    }
  }
}

.btn-success {
  color: $feher;
  text-transform: uppercase;
  font-weight: bold;
  transition: background-color 0.1s ease;

  &:hover,
  &:focus {
    color: $sotetkek;
    background: $feher;
    border-color: $sotetkek;
    transition: background-color 0.1s ease;
  }
}

.kapcsolodo-menupont {
  margin: 30px 0;
  .kapcsolodo-cim {
    margin: 0 -50px;
    background: #ffeebd;
    padding: 20px 50px;

    h4 {
      font-size: 21px;
      font-weight: 300;
      text-transform: uppercase;
    }
  }

  > ul {
    padding-left: 12px !important;
    list-style-position: inside !important;

    > li > a {
      color: #999;
      font-style: italic;
      font-weight: 300;

      &:hover,
      &:focus {
        color: $sotetkek;
      }
    }
  }
}

.tartalom-sav {
  padding-right: 30px;
}

.tovabbi-resz {
  .btn-success {
    color: $feher;
    &:hover,
    &:focus {
      color: $sotetkek;
      background: $feher;
      border-color: $sotetkek;
    }
  }
}

.oldalsav {
  padding-left: 24px;

  h4 {
    margin-top: 0;
    font-size: 36px;
    font-weight: 300;
    color: $zold;
    text-transform: uppercase;
  }

  .oldalsav-almenu {
    border-radius: 0;
    .panel-heading {
      background: $brand-primary;
      border-radius: 0;
      .panel-title {
        text-transform: uppercase;
        font-size: 18px;
        color: white;
      }
    }
    .panel-body {
      border-radius: 0;

      ul {
        list-style-position: outside;
        list-style: none;
        padding-left: 27px;
        position: relative;

        li {
          margin-bottom: 5px;
          position: relative;
          padding-left: 10px;
          line-height: 1.5;
          a {
            color: $text-color;
            font-size: 15px;

            &:hover,
            &:focus {
              color: $brand-primary;
            }
          }
        }

        li::before {
          content: '▪';
          color: $zold;
          display: inline-block;
          width: 1em;
          height: 0.6em;
          margin-left: -0.5em;
          font-size: 25px;
          position: absolute;
          left: 0;
          top: -0.3em;
        }
      }
    }
  }

  .oldalsav-hir-elem {
    margin-bottom: 50px;
    time {
      font-size: 15px;
      font-weight: 300;
      color: #999;
    }

    h3 {
      margin-top: 5px;
      font-weight: bold;
      font-size: 18px;
      margin-bottom: 25px;
      a {
        color: $text-color;

        &:hover,
        &:focus {
          color: $zold;
        }
      }
    }

    img {
      margin-bottom: 10px;
    }

    .lead {
      font-size: 15px;
      margin-bottom: 0;
      .reszletek-link {
        text-transform: uppercase;
        font-weight: 900;
        font-size: 12px;

        &:hover,
        &:focus {
          color: $sotetkek;
        }
      }
    }
  }
}
