.szoveg {
  padding: 20px 15px;

  h1 {
    font-size: 28px;
  }

  .blokk-elem {
    .panel-felso {
      margin: 1px -15px;
      .panel-heading {
        padding: 22px 20px 22px 60px;
        background-position: 20px 50%;
      }
    }
  }
}