.kerdoiv-body {
  .oldalsav {
    display: none;
  }
  .tartalom-sav {
    width: 100% !important;
  }
}

.kerdoivKit {
  .greeting {
    text-align: center;
    background-color: #f0f0f0;
    margin: 0;
    padding: 1rem 0;
  }
  header {
    padding: 0.5rem;
    background-color: #ddd;
    h2 {
      text-align: center;
    }
    ul {
      list-style-type: none;
      text-align: center;
      li {
        display: inline-block;
        margin: 0 1rem;
        &:nth-of-type(odd) {
          font-weight: bold;
        }
      }
    }
  }
  .kerdesKit {
    padding: 0.5rem;
    border-bottom: 1px solid #333;
    margin-left: 0;
    margin-right: 0;
    &:last-of-type {
      border: 0;
    }
    &:first-of-type {
      background-color: #eee;
      border-bottom: 1px solid;
    }

    .kerdes {
    }
    .valasz {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .commentKit {
    margin-top: 5rem;
    padding: 0.5rem;
    p {
      font-weight: bold;
    }
    textarea {
      width: 100%;
      height: 20rem;
    }
    button {
      margin-left: auto;
      display: block;
    }
  }
}
