.hirek-aloldal, .allajajanlat-aloldal, .referencia-aloldal {
  #hirek_morepages, #referencia_morepages {
    .media {
      .media-body {
        .media-heading {
          font-size: 21px;
        }
      }
    }
  }
}