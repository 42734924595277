.kepzes-aloldal {
  .ajanlat {
    padding: 0 15px 30px;
    h4 {
      font-size: 28px;
    }
  }

  .kepzes-mobil-elem {
    padding: 15px;
    h4 {
      font-size: 16px;
      font-weight: 600;
    }
    p {
      padding-left: 30px;
    }
  }
}