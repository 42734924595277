.letoltheto-dokumentumok {
  .tartalom-sav {
    padding-right: 0;
    margin-bottom: 30px;
  }

  .media{
    .media-left{
      display: none;
    }

    .media-body{
      padding-left: 0;
    }
  }
}