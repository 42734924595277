.kereses-aloldal {
  .kereses-lista {
    padding: 40px 50px;

    h4 {
      color: #999;
      .kifejezes {
        font-style: italic;
      }
    }

    .kereses-elem {
      margin: 20px 0;
      a {
        font-weight: 600;
        font-size: 18px;

        &:hover, &:focus {
          color : $sotetkek;
        }
      }
    }
  }
}