header {
  .container-fluid {
    .navbar-header {
      padding: 0 15px;
      .navbar-brand {
        padding-left: 15px;
        img {
          max-width: 180px;
        }
      }
    }
    .navbar-collapse {
      max-height: 380px;
    }

    .navbar-nav {
      display: block;
      margin: 0;
      padding-bottom: 15px;

      > div {
        display: block;
        text-align: center;
        a {
          display: block;
          padding: 10px 15px;
          background-position: 85% 50% !important;
        }

        &.open {
          .dropdown-menu {
            li {
              > a {
                color: white;
                text-align: center;
              }
            }
          }
        }
      }
    }
  }

  .kereses-sav {
    .form-group {
      max-width: 520px;
    }
  }
}