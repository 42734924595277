footer {
  .social {
    a img {
      width: 65px;
    }
  }

  padding-top: 30px;
  padding-bottom: 60px;
  hr {
    border-color: #a3a3a3;
  }

  .felso-margin {
    margin-top: 15px;
  }

  .footer-felso {
    margin-top: 10px;
    color: $text-color-light;
    font-weight: bold;
    font-size: 16px;
    > a {
      color: $text-color-light;

      &:hover,
      &:focus {
        color: $zold;
      }

      > p {
        display: inline-block;
      }
    }
    img {
      margin-right: 20px;
    }
  }

  .footer-tartalom {
    color: $text-color-light;

    h1,
    h2,
    h3 {
      font-size: 16px;
      font-weight: bold;
      margin-top: 26px;
      margin-bottom: 20px;
      text-transform: uppercase;
    }

    ul,
    ol {
      list-style: none;
      padding: 0;
      li {
        color: $text-color-light;
        margin: 12px 0;
        a {
          color: $text-color-light;

          &:hover,
          &:focus {
            color: $zold;
          }
        }
      }
    }
  }
}
