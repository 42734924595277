.hirek-aloldal, .allasajanlat-aloldal, .referencia-aloldal {
  .oldal-resz > .row > .tartalom-sav > .bg-white {
    background: transparent;
    padding: 0;
    margin-bottom: 20px;
  }

  #hirek_morepages, #referencia_morepages {
    .media {
      padding: 50px;

      .media-body {

        &.padding-30 {
          padding-left: 18px;
        }
        time {
          font-size: 15px;
          font-weight: 300;
        }

        .media-heading {
          font-size: 24px;
          font-weight: bold;
          margin-bottom: 25px;
          a {
            color: $text-color;

            &:hover, &:focus {
              color: $zold;
            }
          }
        }
        .szolgaltatas {
          margin-bottom: 5px;
        }

        .lead {
          font-size: 15px;
          margin-bottom: 0;
        }

        .reszletek-link {
          text-transform: uppercase;
          font-weight: 900;
          font-size: 12px;

          &:hover, &:focus {
            color: $sotetkek;
          }
        }
      }
    }
  }

  .tovabbi-resz {
    margin-top: 30px;

    .btn-success {
      padding: 9px 35px 10px;
      font-size: 20px;
    }
  }
}

.hirek-aloldal{
  .oldalsav{
    .oldalsav-hir-elem,.oldalsav-cim{
      display: none;
    }
  }
}