.szoveg {
  padding: 40px 35px;

  .blokk-elem {
    .panel-felso {
      margin: 1px -35px;
    }
  }

  table {
    td {
      font-size: 12px;
      padding: 9px 10px;
      p {
        font-size: 12px;
      }
    }
  }
}