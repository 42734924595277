.fooldal {
  .carousel-control {
    background-image: none;
    img {
      position: absolute;
      top: 50%;
    }
    &.left {
      img {
        left: 20px;
        width: 15px;
      }
    }
    &.right {
      img {
        right: 20px;
        width: 15px;
      }
    }
  }


  .carousel-indicators {
    display: none;
  }

  .carousel-caption {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 10px 75px;
    width: 100%;

    .slider-cim {
      font-size: 18px;
      margin-bottom: 5px;
    }

    p {
      font-size: 15px;
      margin-bottom: 25px;
    }

    .btn-success {
      font-size: 12px;
    }
  }

  .fooldal-rolunk {
    padding: 40px 30px;
    font-size: 16px;
  }

  .harmas-resz {
    margin-top: 40px;
    > .row {
      > div {
        border-left: none !important;
        border-right: none !important;
        border-bottom: 1px solid #f0f0f0;
        .btn {
          padding: 8px 20px;
          font-size: 15px;
        }
      }
    }
  }

  @media (max-width: $screen-xs-max) {
    .harmas-resz {
      > .row {
        > div {
          border-left: none !important;
          border-right: none !important;
          border-bottom: 1px solid #f0f0f0;
          .btn {
            padding: 8px 25px;
            font-size: 16px;
          }
        }
      }
    }
  }

  .fooldal-ajanlatkero {
    @media (max-width: $screen-xs-max) {
      margin: 20px 15px;
    }
    padding: 30px 20px;
    @media (max-width: 320px) {
      h3{
        font-size: 30px;
      }
    }
  }

  .ajanlatkero-szoveg {
    h1, h2, h3 {
      font-size: 26px;
    }
    p{
      font-size: 17px;
    }
  }
}