.hirek-aloldal, .allajajanlat-aloldal, .referencia-aloldal {
  #hirek_morepages, #referencia_morepages {
    .media {
      padding: 20px;
      .media-left {
        margin-bottom: 15px;
        img {
          display: block;
          margin-left: auto;
          margin-right: auto;
        }
      }

      .media-left, .media-body {
        display: block;
        max-width: 100%;
        .media-heading {
          font-size: 20px;
        }

        &.padding-30 {
          padding: 0;
        }
      }
    }
  }
}