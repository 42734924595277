@import "_bootstrap.scss";
@import "_header.scss";
@import "_navbar-toggle-animation.scss";
@import "_footer.scss";
@import "_layout.scss";
@import "_szoveg.scss";
@import "_fooldal.scss";
@import "_letoltheto.scss";
@import "_hirek.scss";
@import "_referenciak.scss";
@import "_kepzes.scss";
@import "_ajanlatkero.scss";
@import "_kapcsolat.scss";
@import "_kereses.scss";
@import "_kerdoiv.scss";

@media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
  @import "md/_header.scss";
  @import "md/_fooldal.scss";
  @import "md/_footer.scss";
  @import "md/_layout.scss";
  @import "md/_hirek.scss";
  @import "md/_szoveg.scss";
}

@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
  @import "sm/_footer.scss";
  @import "sm/_layout.scss";
}

@media (max-width: $screen-sm-max) {
  @import "xs/_header.scss";
  @import "xs/_footer.scss";
  @import "xs/_layout.scss";
  @import "xs/_fooldal.scss";
  @import "xs/_hirek.scss";
  @import "xs/_dokumentumok.scss";
  @import "xs/_szoveg.scss";
  @import "xs/_kepzes.scss";
}

body {
  padding-top: $navbar-height;
}

a {
  text-decoration: none;
  &:focus, &:hover {
    text-decoration: none;
  }
}

.no-padding {
  padding: 0;
}

.bg-szurke {
  background-color: $szurke;
}

.main-content {
  padding-bottom: 80px;
}

label {
  text-transform: uppercase;
}

label.checkbox-text {
  text-transform: none;
}

input, select, textarea {
  border-radius: 0;
  border-color: #a3a3a3;

  &:focus {
    border-color: $zold;
    box-shadow: inset 0 1px 1px rgba(red($zold), green($zold), blue($zold), 0.075), 0 0 8px rgba(red($zold), green($zold), blue($zold), 0.6);
  }
}

