.navbar-toggle {
	.icon-bar {
		width: 19px;
		@include transition(all .2s ease-out);
	}

	.icon-bar + .icon-bar {
		margin-top: 4px;
	}

	&[aria-expanded=true] {
		$bar-translateX: 2px;
		$bar-translateY: 3px;
		$bar-rotate: 45deg;

		.icon-bar:nth-child(2) {
			@include transform-origin(0 50%);
			transform: rotate($bar-rotate) translate($bar-translateX, -$bar-translateY);
			-webkit-transform: rotate($bar-rotate) translate($bar-translateX, -$bar-translateY);
		}
		.icon-bar:nth-child(3) {
			@include transform-origin(50% 50%);
			@include scale(0,0);
		}
		.icon-bar:nth-child(4) {
			@include transform-origin(0 50%);
			transform: rotate(-$bar-rotate) translate($bar-translateX, $bar-translateY);
			-webkit-transform: rotate(-$bar-rotate) translate($bar-translateX, $bar-translateY);
		}
	}
}